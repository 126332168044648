import axiosInstance from "./plugins/axios.js";
import {ca} from "vuetify/locale";

// Створюємо інстанс Axios з базовою URL
const apiClient = axiosInstance.create({
    baseURL: window.location.protocol || 'http://127.0.0.1:8000',
    headers: {
        'Content-Type': 'application/json',
        // Додаткові заголовки
    },
    withCredentials: true // Дозволяє надсилати файли cookie, якщо потрібно
});

// Обробка перехоплення запитів для додавання токену авторизації
apiClient.interceptors.request.use(
    config => {
        const token = localStorage.getItem('authToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Обробка перехоплення відповідей для обробки помилок
apiClient.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401) {
            // Обробка неавторизованого доступу
            console.log('Неавторизований доступ');
        }
        return Promise.reject(error);
    }
);

// Приклад методів API
export default {
    // Інші API методи
    login(credentials) {
        return apiClient.post('/login', credentials);
    },

    logout() {
        return apiClient.post('/logout');
    },
    getProducts() {
        return apiClient.post('/1c/',{
            method: 'getCategory',
            params: {}
        });
    },
    getClients() {
        return apiClient.post('/1c/',{
            method: 'getClients',
            params: {}
        });
    },
    saveOrder(model) {
        return apiClient.post('/1c/',{
            method: 'saveOrder',
            params: model
        });
    },
    getOrdersList(params){
        return apiClient.post('/1c/',{
            method: 'getOrdersList',
            params: params,
        });
    },
    getOrderByNumber(params){
        return apiClient.post('/1c/',{
            method: 'getOrderByNumber',
            params: params||[]
        });
    },
    deleteOrder(number) {
        return apiClient.post('/1c/',{
            method: 'deleteOrder',
            params: number
        });
    },
    pdfOrder(number) {
        return apiClient.post('/1c/',{
            method: 'pdfOrder',
            params: number
        });
    },
    changeStatusOrder(params){
        return apiClient.post('/1c/',{
            method: 'changeStatusOrder',
            params: params||[]
        });
    },
    imageDownload(params){
        return apiClient.post('/image-download/',{});
    },
    searchProducts(search_str) {
        return apiClient.get('/search_products.json');
    },
    getPayLink(params){
        return apiClient.post('/1c/',{
            method: 'getPayLink',
            params: params||[]
        });
    },
    async getProductsFromCategory(category_slug) {
        let category = null;
        const res = await apiClient.get(`/products.json`)
        category = res.data.categories.filter(el => {
            return el.slug == category_slug
        })
        return category[0].products;


    }
};
