import {defineStore} from 'pinia';
import Cookies from "js-cookie";

export const useConfigStore = defineStore('config', {
    state: () => ({
        items: [], //Старые настройки
        groups: [], //Групи пользователь
        currentUserRole: null, //Текущая роль пользователя
    }),
    actions: {
        setUserRole(role) {
            this.currentUserRole = role;
            Cookies.set('role', role);
        },

    },
    persist: true
})
