<template>
    <component :is="layout">
        <router-view />
    </component>
</template>

<script>
import DefaultLayout from "./DefaultLayout.vue";
import AuthLayout from "./AuthLayout.vue";

export default {
    computed: {
        layout() {
            return this.$route.meta.layout || DefaultLayout; // За замовчуванням, якщо лаяут не вказаний
        }
    },
    components: {
        DefaultLayout,
        AuthLayout
    }
};
</script>
