import {createApp} from "vue";
import App from "./layouts/App.vue";
import vuetify from "./vuetify";
import router from "./router/index.js"
import {createPinia} from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import vueDebounce from 'vue-debounce'
import mitt from 'mitt';


const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const emitter = mitt();
const app = createApp(App)
    .use(vuetify)
    .use(pinia)
    .use(router)
    .directive('debounce', vueDebounce({lock: true}))

app.config.globalProperties.$mitt = emitter;

app.mount("#app");
