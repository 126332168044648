import {defineStore} from 'pinia'
import axiosInstance from "../plugins/axios.js";
import {useConfigStore} from "./config.js";
import Cookies from "js-cookie";
import router from "../router/index.js";


export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: (localStorage.getItem('user')!='undefined'?JSON.parse(localStorage.getItem('user')):null) || null,
        token: localStorage.getItem('token') || null,
        isAdmin: false,
    }),

    getters: {
        isAuthenticated: (state) => !!state.token,
        currentUser: (state) => state.user
    },

    actions: {
        async login(email, password) {
            const configStore = useConfigStore();

            try {
                axiosInstance.defaults.baseURL = import.meta.env.VITE_API_URL ? `${import.meta.env.VITE_API_URL}/auth` : 'http://localhost:8000/auth'
                const response = await axiosInstance.post('login', {email, password,csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')},{
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                // Оновлення локального сховища
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('user', JSON.stringify(response.data.user))
                // Оновлення стану Pinia
                this.token = response.data.token
                this.user = response.data.user
                this.isAdmin = response.data.isAdmin
                configStore.groups = response.data.user.groups;
                configStore.items = response.data.items;
                configStore.currentUserRole = response.data.items[0].id;
                Cookies.set('role', response.data.items[0].id);
                return response.data
            } catch (error) {
                console.error('Login failed', error)
                throw error
            }
        },
         logout() {
            const configStore = useConfigStore();
            try {
                axiosInstance.defaults.baseURL = import.meta.env.VITE_API_URL ? `${import.meta.env.VITE_API_URL}/auth` : 'http://localhost:8000/auth'
               const response =   axiosInstance.get('logout')
                // Очищення локального сховища
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                this.user = null;
                this.token = null;
                this.isAdmin = false;
                configStore.$reset();
                Cookies.remove('role',null);
                delete axiosInstance.defaults.headers.common["Authorization"];
                router.push('/login');
            } catch (error) {
                console.error('Logout failed', error)
                throw error
            }
        }
    },
    persist: true
})
