<script>
import Icon from "@/js/components/icon.vue";
import api from "@/js/api.js";
import Cookies from "js-cookie";
import OrderStatus from "../components/order-status.vue";
import {useConfigStore} from "../stores/config.js";

const PAYMENT_STATUSES = {
    pending: 'yellow',
    paid: 'green'
}

export default {
    setup(){
      const configStore = useConfigStore();
      return {configStore}
    },
    name: "OrdersView",
    components: {Icon, OrderStatus},


    data: () => ({
        isLoading: false,
        dialog: false,
        dialogDelete: false,
        show_filters: false,
        show_created_from: false,
        show_created_to: false,
        show_date_from: false,
        show_date_to: false,
        is_mobile: false,
        showRemoveDialog: false,
        tmpRemoveItem: null,
        headers: [
            {
                title: '№',
                align: 'start',
                key: 'number',

            },
            {
                title: 'Створено',
                key: 'create_at',
                class: 'd-none d-md-table-cell'
            },
            {
                title: 'На дату',
                key: 'date',

            },
            {
                title: 'Клієнт',
                key: 'client',
                // class: 'd-none d-md-table-cell'
            },
            {
                title: 'Сума',
                key: 'sum',
            },
            {
                title: 'Статус',
                key: 'status',
                sortable: false,
                class: 'd-none d-md-table-cell'
            },
            {
                title: 'Дія',
                key: 'actions',
                sortable: false,
                class: 'd-none d-md-table-cell'
            },
        ],
        search: {
            filter: {
                created_at_from: null,
                created_at_to: null,
                date_from: null,
                date_to: null,
                number: null
            }
        },
        show_expand: false,
        items: [],
        expandedItems: [],
        statuses: [
            {
                key: 'completed',
                title: 'Виконаний',
                color: 'green'
            },
            {
                key: 'new',
                title: 'В обробці',
                color: 'amber'
            },
            {
                key: 'in progress',
                title: 'Прийнятий',
                color: 'light-blue-accent-4'
            },
            {
                key: 'cancelled',
                title: 'Відмінений',
                color: 'red'
            },

        ],
        statusesEdit: [],
        model: {
            page: 1,
            onPage: 20,
            count: 0,
            orderBy: {numder: "desc"},
            filter: {
                created_at_from: '',
                created_at_to: '',
                date_from: '',
                date_to: '',
                number: '',
            }
        },
        sortModel: [{
            key: 'number',
            order: 'desc'
        }],
        isPaid: false
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
        // Створено від
        computedCreatedFromFormatted() {
            return this.formatDate(this.search.filter.created_at_from)
        },
        // Створено до
        computedCreatedToFormatted() {
            return this.formatDate(this.search.filter.created_at_to)
        },
        // На дату від
        computedDateFromFormatted() {
            return this.formatDate(this.search.filter.date_from)
        },
        // На дату до
        computedDateToFormatted() {
            return this.formatDate(this.search.filter.date_to)
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        let token = this.configStore.currentUserRole;
        let role = this.configStore.items.filter((i) => i.id === token)[0];
        this.statuses = role.statuses;
        this.statusesEdit = role.statusesEdit;
        this.isPaid = role.isPaid && parseInt(role.isPaid) > 0
        this.initialize();
        if(role.id === 'type1'){
            this.headers.map(item =>{
                if(item.key == 'client') item.title = 'Постачальник'
            })
        }
    },
    mounted() {
        this.is_mobile = window.innerWidth < 960;
        this.show_expand = window.innerWidth < 960;
        this.expandedItems = [];
        window.addEventListener('resize', () => {
            this.is_mobile = window.innerWidth < 960;
            this.show_expand = window.innerWidth < 960;
            if (!this.show_expand) {
                this.expandedItems = [];
            }
        })
    },


    methods: {
        pagination() {
            return {
                descending: 'desc',
                sortBy: 'number',
                rowsPerPage: this.model.onPage,
                page: this.model.page,
                totalItems: 0,
            }
        },
        paginate(e) {
            // this.model.page=e;
            this.initialize()
        },
        async payment(item) {
            this.$mitt.emit('show-global-loader', true);
            let response = await api.getPayLink({number: item.number});
            if (response && response.data && response.data.data && response.data.data.invoiceId) {
                window.location.href = response.data.data.invoiceId
            }
            this.$mitt.emit('show-global-loader', false);
            console.log(response)
        },
        removeConfirmation(item) {
            this.showRemoveDialog = true;
            this.tmpRemoveItem = item.number;


        },
        async changeStatus(numberOrder, value) {
            console.log(numberOrder, value)
            try {
                let modelInst = this.items.filter((o) => o.number === numberOrder)[0];
                if (!modelInst.uid) {
                    return;
                }
                this.$mitt.emit('show-global-loader', true);
                let model = {number: modelInst.number, status: value};
                let response = await api.changeStatusOrder(model);
                this.$mitt.emit('show-global-loader', false);
            } catch (e) {
                console.error(e)
            }
            return true
        },
        async printOrder(numberOrder) {
            this.$mitt.emit('show-global-loader', true);
            let response = await api.pdfOrder(numberOrder);
            let pdfData = response.data.data;
            this.$mitt.emit('show-global-loader', false);
            this.printCustomContent(pdfData)
        },
        printCustomContent(pdfData) {
            // Створюємо нове вікно
            var printWindow = window.open('', '', 'height=600,width=800');
            // Додаємо HTML-вміст, який хочемо надрукувати
            printWindow.document.write('<html><head><title>Друк</title>');
            printWindow.document.write('</head><body>');
            printWindow.document.write(`<iframe src="${pdfData}" width="100%" height="100%"></iframe>`);
            printWindow.document.write('</body></html>');
        },
        async deleteOrder() {
            this.$mitt.emit('show-global-loader', true);
            let response = await api.deleteOrder(this.tmpRemoveItem);
            if (response.data && response.data.status && response.data.status === true) {
                this.initialize();
            }
            this.tmpRemoveItem = null;
            this.showRemoveDialog = false;
            this.$mitt.emit('show-global-loader', false);
        },
        expand(item) {
            console.log('expand')
            // Method to toggle row expansion
            if (this.is_mobile) {
                const index = this.expandedItems.indexOf(item.number);
                if (index === -1) {
                    this.expandedItems.push(item.number);
                } else {
                    this.expandedItems.splice(index, 1);
                }
            }
        },
        // Функція для форматування дати у форматі дд-мм-рррр
        formatDate(date) {
            if (!date) return null;
            if (date.length == 10) return date;
            console.log('date', date)
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Місяці від 0 до 11
            const year = date.getFullYear();
            return `${day}.${month}.${year}`;
        },
        async getOrdersList(model) {
            try {
                let response = await api.getOrdersList(model);
                this.model.page = parseInt(response.data.data.page, 10)
                this.model.onPage = parseInt(response.data.data.onPage, 10)
                this.model.count = parseInt(response.data.data.count, 10)
                return response.data.data.items;

            } catch (error) {
                console.error(error)
                this.$mitt.emit('show_msg', {
                    msg: 'Get orders error'
                })
            }
        },
        async initialize() {
            try {
                this.isLoading = true;
                let model = Object.assign({}, {...this.model});
                model.filter = Object.assign({}, {...this.search.filter});

                for (const i in model.filter) {
                    if (!model.filter[i]) {
                        delete model.filter[i];
                    }
                    if (['created_at_from', 'created_at_to', 'date_from', 'date_to'].indexOf(i) > -1 && this.search.filter[i]) {
                        //console.log(model.filter[i], this.formatDate(new Date(model.filter[i])))
                        console.log(this.formatDate(new Date(this.search.filter[i])), "MODEL")
                        // console.log(this.search.filter[i])
                        model.filter[i] = this.formatDate(new Date(this.search.filter[i]));
                    }
                }
                //console.log(this.model)
                this.items = await this.getOrdersList(model)
                this.isLoading = false;
            } catch (error) {
                console.error(error)
                this.$mitt.emit('show_msg', {
                    msg: 'Get orders error'
                })
                this.isLoading = false;
            }
        },
        clearSearchDate(field) {
            event.stopPropagation();
            this.search.filter[field] = null;
        },

        editItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.desserts.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.desserts[this.editedIndex], this.editedItem)
            } else {
                this.desserts.push(this.editedItem)
            }
            this.close()
        },
        applyFilter() {
            console.log(this.model)
            this.model.page = 1;
            this.initialize();
            this.show_filters = false;
            this.show_created_to = false;
            this.show_created_from = false;
            this.show_date_to = false;
            this.show_date_from = false;
        },
        clearFilter() {
            this.search.filter = this.model.filter = {
                created_at_from: '',
                created_at_tu: '',
                date_from: '',
                date_to: '',
                number: '',
            };
            this.initialize();
            this.show_filters = false;
            this.show_created_to = false;
            this.show_created_from = false;
            this.show_date_to = false;
            this.show_date_from = false;
        },
        isFilter() {
            for (const i in this.search.filter) {
                if (this.search.filter[i] != '' && this.search.filter[i] != null) {
                    return true;
                }
            }
            return false;
        },
        async updateSort(data) {
            if (data[0] && ['status', 'actions'].indexOf(data[0].key) > -1) {
                return;
            }
            if (!data[0]) {
                if (this.sortModel[0].order === 'asc') {
                    data = [...this.sortModel];
                    data[0].order = 'desc'
                } else {
                    data = [...this.sortModel];
                    data[0].order = 'asc'
                }
            }
            ;
            window.event.preventDefault();
            this.model.orderBy = {};
            this.model.orderBy[data[0].key] = data[0].order;
            let d = await this.initialize();
            this.sortModel = data
        }
    },
}

</script>

<template>
    <v-container fluid class="orders__page">

        <v-toolbar color="transparent" flat>
            <v-toolbar-title tag="h4" class="ml-0">Список замовлень</v-toolbar-title>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="text-h5"
                    >Are you sure you want to delete this item?
                    </v-card-title
                    >
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                        >Cancel
                        </v-btn
                        >
                        <v-btn
                            color="blue-darken-1"
                            variant="text"
                            @click="deleteItemConfirm"
                        >OK
                        </v-btn
                        >
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>
        <v-toolbar color="transparent" flat class="mb-4">
            <v-menu
                v-model="show_filters"
                :close-on-content-click="false"
                location="bottom"
                offset="15"
            >
                <template v-slot:activator="{ props }">
                    <v-btn
                        color="primary"
                        variant="tonal"
                        v-bind="props"
                        :prepend-icon=" !show_filters ? 'filter_alt':'close'"
                    >
                        Фільтр
                    </v-btn>
                </template>
                <v-card

                    class="pa-2 responsive__filter-card"
                >
                    <v-form @submit.prevent="applyFilter" ref="form">
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="12"

                                >
                                    <v-text-field
                                        v-model="search.filter.number"
                                        label="Номер заказу"
                                        variant="underlined"
                                        hide-details="true"
                                    >

                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="6"
                                    class="pa-1 pa-sm-3"
                                >
                                    <v-menu
                                        v-model="show_created_from"
                                        :close-on-content-click="false"
                                        location="bottom"
                                        offset="15"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-text-field
                                                v-model="computedCreatedFromFormatted"
                                                label="Створено від:"
                                                hide-details="true"
                                                prepend-inner-icon="event"
                                                :append-inner-icon="search.filter.created_at_from? 'close': ''"
                                                @click:appendInner="clearSearchDate('created_at_from')"
                                                readonly
                                                v-bind="props"
                                                variant="underlined"

                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="search.filter.created_at_from"
                                            hide-header
                                            @update:modelValue="show_created_from = false">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="6"
                                    class="pa-1 pa-sm-3"
                                >
                                    <v-menu
                                        v-model="show_created_to"
                                        :close-on-content-click="false"
                                        location="bottom"
                                        offset="15"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-text-field
                                                v-model="computedCreatedToFormatted"
                                                label="Створено до:"
                                                hide-details="true"
                                                prepend-inner-icon="event"
                                                :append-inner-icon="search.filter.created_at_to? 'close': ''"
                                                @click:appendInner="clearSearchDate('created_at_to')"
                                                readonly
                                                v-bind="props"
                                                variant="underlined"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="search.filter.created_at_to"
                                            hide-header
                                            @update:modelValue="show_created_to = false">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="6"
                                    class="pa-1 pa-sm-3"
                                >
                                    <v-menu
                                        v-model="show_date_from"
                                        :close-on-content-click="false"
                                        location="bottom"
                                        offset="15"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-text-field
                                                v-model="computedDateFromFormatted"
                                                label="На дату від:"
                                                hide-details="true"
                                                prepend-inner-icon="event"
                                                :append-inner-icon="search.filter.date_from? 'close': ''"
                                                @click:appendInner="clearSearchDate('date_from')"
                                                readonly
                                                v-bind="props"
                                                variant="underlined"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="search.filter.date_from"
                                            hide-header
                                            @update:modelValue="show_date_from = false">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="6"
                                    class="pa-1 pa-sm-3"
                                >
                                    <v-menu
                                        v-model="show_date_to"
                                        :close-on-content-click="false"
                                        location="bottom"
                                        offset="15"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-text-field
                                                v-model="computedDateToFormatted"
                                                label="На дату до:"
                                                hide-details="true"
                                                prepend-inner-icon="event"
                                                :append-inner-icon="search.filter.date_to? 'close': ''"
                                                @click:appendInner="clearSearchDate('date_to')"
                                                readonly
                                                v-bind="props"
                                                variant="underlined"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="search.filter.date_to"
                                            hide-header
                                            @update:modelValue="show_date_to = false">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6"
                                       class="pa-1 pa-sm-3"
                                >
                                    <v-btn class="text-xs-2 text-1" color="transparent" type="submit" width="100%"
                                           prepend-icon="check"
                                    >
                                        <template #default>
                                            <span class="text-caption  text-sm-button text-uppercase">
                                                Застосувати
                                            </span>
                                        </template>

                                    </v-btn>

                                </v-col>
                                <v-col col="6"
                                       class="pa-1 pa-sm-3"
                                >
                                    <v-btn class="text-xs-2 text-1" color="red" width="100%" prepend-icon="close"
                                           @click="clearFilter()"
                                    >
                                        <template #default>
                                            <span class="text-caption text-sm-button text-uppercase ">
                                                Скинути
                                            </span>
                                        </template>

                                    </v-btn>
                                </v-col>
                            </v-row>

                        </v-container>
                    </v-form>
                </v-card>
            </v-menu>
            <v-spacer></v-spacer>
            <v-btn color="green-darken-1" variant="tonal" prepend-icon="add" :to="{name:'create-order'}">
                Нове замовлення
            </v-btn>
        </v-toolbar>

        <v-data-table
            :headers="headers"
            :items="items"
            :sortBy="sortModel"
            @update:sortBy="updateSort"
            disable-sort
            :items-per-page="model.onPage"
            item-value="number"
            v-model:expanded="expandedItems"
            :loading="isLoading"
            loading-text="Завантаження...."

        >
            <template v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }">
                <tr class="bg-grey-lighten-2 small__padding">
                    <template v-for="column in columns" :key="column.key">
                        <td :class="column.class || ''">
                            <span class="mr-2 cursor-pointer font-weight-bold"
                                  @click="() => toggleSort(column)">{{ column.title }}</span>
                            <template v-if="isSorted(column)">
                                <v-icon :icon="getSortIcon(column)"></v-icon>
                            </template>
                        </td>
                    </template>
                </tr>
            </template>
            <template v-slot:item="{item}">
                <tr class="small__padding">
                    <td @click="expand(item)">
                        <v-btn variant="text" color="blue"
                               :to="{name:'edit-order', params:{id: item.number}}"
                        >
                            {{ item.number }}
                        </v-btn>
                    </td>
                    <td v-if="!is_mobile">
                        {{ item.create_at.split(' ')[0] }}
                    </td>
                    <td @click="expand(item)">
                        <template v-if="!is_mobile">
                            {{ item.date.split(' ')[0] }}
                        </template>
                        <template v-else>
                            {{ item.date.split(' ')[0] }}
                        </template>
                    </td>
                    <td @click="expand(item)">
                        {{ item.client.name }}
                    </td>
                    <td @click="expand(item)" class="ga-5 d-flex align-center justify-space-between">
                        <span :style="{'color':item.isPaid?'green':'red'}" class="font-weight-bold">
                            {{ item.sum }} грн
                        </span>
                        <v-btn
                            v-if="!item.isPaid&&isPaid && !is_mobile"
                            variant="tonal"
                            class="font-weight-bold"
                            @click="payment(item)"
                        >
                            Сплатити
                        </v-btn>
                    </td>
                    <td v-if="!is_mobile">
                        <OrderStatus :item="item" :statuses="statuses" @change-status="changeStatus" />
                    </td>
                    <td v-if="!is_mobile">
                        <v-menu
                            close-on-content-click
                            location="left"
                            offset="10"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="x-small"
                                    color="primary"
                                    icon="more_horiz"
                                    v-bind="props"
                                >
                                </v-btn>
                            </template>
                            <v-card>
                                <v-list>
                                    <v-list-item>
                                        <v-btn
                                            width="100%"
                                            class="tex justify-start"
                                            :to="{name:'edit-order', params:{id: item.number}}"
                                            prepend-icon="visibility"
                                        >
                                            Переглянути
                                        </v-btn>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-btn
                                            @click.prevent="removeConfirmation(item)"
                                            width="100%"
                                            class="tex justify-start"
                                            prepend-icon="delete"
                                            :disabled="this.statusesEdit.indexOf(item.status)==-1"
                                        >
                                            Видалити
                                        </v-btn>

                                    </v-list-item>
                                    <v-list-item>
                                        <v-btn
                                            width="100%"
                                            class="tex justify-start"
                                            prepend-icon="print"
                                            @click="printOrder(item.number)"
                                        >
                                            Друкувати
                                        </v-btn>
                                    </v-list-item>
                                </v-list>
                            </v-card>

                        </v-menu>
                    </td>
                    <!--                    <td v-if="is_mobile">-->
                    <!--                        <v-btn @click="expand(item)" size="small" icon="keyboard_arrow_down" variant="text">-->
                    <!--                        </v-btn>-->
                    <!--                    </td>-->
                </tr>
            </template>
            <template v-slot:expanded-row="{ columns, item }">
                <tr>
                    <td :colspan="columns.length">
                        <v-table>
                            <tbody>
                            <tr>
                                <td><b>Створено</b></td>
                                <td>{{ item.create_at }}</td>
                            </tr>
                            <!--                            <tr>-->
                            <!--                                <td><b>На дату</b></td>-->
                            <!--                                <td>{{ item.date }}</td>-->
                            <!--                            </tr>-->
                            <tr>
                                <td><b>Сума</b></td>
                                <td class="d-flex justify-space-between align-center">
                                    <!--                        TODO: ТУТ ПОДСВЕТКА СТАТУСА ОПЛАТЫ  -->
                                    <span :style="{'color':item?'green':'red'}" class="font-weight-bold">
                                             {{ item.sum }} грн
                                    </span>
                                    <!--                        TODO:тут проверять нужна ли кнопка на оплату-->
                                    <v-btn
                                        v-if="item"
                                        variant="tonal"

                                        class="font-weight-bold"
                                        @click="payment(item)"
                                    >
                                        <v-icon icon="credit_card"></v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            <tr>
                                <td><b>Статус</b></td>
                                <td>
                                    <OrderStatus :item="item" :statuses="statuses" @change-status="changeStatus" />
                                </td>
                            </tr>
                            </tbody>
                        </v-table>
                        <v-row class="pb-3 mt-3">
                            <v-col cols="4">
                                <v-btn
                                    width="100%"
                                    class="tex"
                                    :to="{name:'edit-order', params:{id: item.number}}"
                                >
                                    <v-icon icon="visibility"></v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="4">
                                <v-btn
                                    width="100%"
                                    class="tex"
                                    @click.prevent="removeConfirmation(item)"
                                    :disabled="this.statusesEdit.indexOf(item.status)==-1"
                                >
                                    <v-icon icon="delete"></v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="4">
                                <v-btn
                                    width="100%"
                                    class="tex"
                                    @click="printOrder(item.number)"
                                >
                                    <v-icon icon="print"></v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </td>
                </tr>


            </template>
            <template v-slot:no-data>
                {{ isFilter() ? 'За вказаним фільтром записи відсутні' : 'У вас ще немає замовлень' }}
            </template>
            <template v-slot:bottom>
                <div class="py-5 px-5">
                    <v-row class="d-flex align-center justify-end ga-5">
                        <div>
                            Елементів на сторінці:
                        </div>
                        <v-select
                            :items="[20,30,40,50]"
                            v-model="model.onPage"
                            density="compact"
                            max-width="100"
                            hide-details
                            variant="outlined"
                            @update:model-value="paginate"
                        ></v-select>
                    </v-row>
                </div>

                <v-pagination
                    density="compact"
                    :total-visible="5"
                    :length="Math.ceil(model.count/model.onPage)"
                    v-model="model.page"
                    @update:modelValue="paginate"
                    :show-first-last-page="true"
                >

                </v-pagination>
            </template>
        </v-data-table>

        <v-dialog
            max-width="400"
            persistent
            v-model="showRemoveDialog"
            z-index="99999999999"
            app
        >
            <v-card
                prepend-icon="info"
                title="Видалити замовлення?"
            >
                <template v-slot:actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="showRemoveDialog = false">
                        Відмінити
                    </v-btn>
                    <v-btn
                        variant="tonal"
                        @click="deleteOrder"
                    >
                        Так
                    </v-btn>
                </template>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<style lang="scss">
.status__select {
    .v-field__append-inner {
        display: none !important;
    }
}

.responsive__filter-card {
    min-width: 500px;
}

@media screen and (max-width: 600px) {
    .responsive__filter-card {
        min-width: 300px;
    }
    .small__padding {
        td {
            padding: 0 5px !important;
        }
    }
}

.mob__table-info {
    //display: none;
}
</style>
