<template>
    <v-container class="fill-height" fluid>
        <v-row justify="center" align="center" class="fill-height">
            <v-col cols="12" sm="6" md="4">
                <v-card class="pa-6 text-center" elevation="6">
                    <img src="@/assets/img/logo_crop.png" alt="" style="max-width: 100%; height: auto; object-fit: contain">
                    <v-form ref="form" v-model="valid" @submit.prevent="submit">
                        <v-text-field
                            variant="outlined"
                            v-model="login"
                            label="Логін"
                            :rules="[rules.required]"
                            class="mb-4"
                            prepend-inner-icon="person"
                        />
                        <v-text-field
                            v-model="password"
                            label="Пароль"
                            :type="showPassword? 'test': 'password'"
                            :rules="[rules.required]"
                            variant="outlined"
                            prepend-inner-icon="lock"
                            :append-inner-icon="!showPassword ? 'visibility' : 'visibility_off'"
                            @click:appendInner="showPassword = !showPassword"
                        />
                        <v-btn :disabled="!valid" color="primary" block class="mt-4" type="submit">
                            Відправити
                        </v-btn>
                    </v-form>
                    <!--<v-btn variant="text" class="mt-2" @click="forgotPassword">
                        Забули пароль?
                    </v-btn>-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { useAuthStore } from '@/js/stores/auth'
import { useRouter } from 'vue-router'
export default {
    setup() {
        const authStore = useAuthStore()
        const router = useRouter()
        return { authStore, router }
    },
    data() {
        return {
            showPassword: false,
            login: "",
            password: "",
            valid: false,
            rules: {
                required: (v) => !!v || "Це поле є обов'язковим",
            },
        };
    },
    methods: {
      async  submit() {
            if (this.$refs.form.validate()) {
                try {
                    await this.authStore.login(this.login, this.password);
                    await this.router.push('/');
                } catch (error) {
                    alert('Не вдалось увійти')
                }
            }
        },
        forgotPassword() {
            alert("Посилання для відновлення пароля відправлено!");
        },
    },
};
</script>

<style scoped>
.v-card {
    border-radius: 12px;
}
</style>
