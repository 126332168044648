import axios from 'axios'
import {useAuthStore} from "../stores/auth.js";

const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_API_URL || 'http://localhost:8000/api'
})

axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token')
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// Додавання інтерцептора відповідей для обробки невторизованих запитів
axiosInstance.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config

        // Якщо отримано 401 статус і запит іще не було повторено
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true

            try {
                // Отримання нового токену або виход
                const authStore = useAuthStore()
                await authStore.logout()

                // Перенаправлення на сторінку входу
                window.location.href = '/login'
            } catch (logoutError) {
                return Promise.reject(logoutError)
            }
        }

        return Promise.reject(error)
    }
)

export default axiosInstance
