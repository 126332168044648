<template>
    <v-app>
        <v-main class="content" style="min-height: 300px;">
            <slot/>
        </v-main>
    </v-app>
</template>
<script>
export default {
    name: 'AuthLayout'
}
</script>
