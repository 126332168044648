import {createRouter, createWebHistory} from 'vue-router';
import OrdersView from "@/js/views/OrdersView.vue";
import OrderView from "@/js/views/order/OrderView.vue";
import LoginView from "@/js/views/LoginView.vue";
import CategoryView from "@/js/views/order/catalog/CategoryView.vue";
import CategoryChildView from "@/js/views/order/catalog/CategoryChildView.vue";
import { useAuthStore} from "../stores/auth.js";
import Cookies from "js-cookie";

import AuthLayout from "../layouts/AuthLayout.vue";

const routes = [
    {
        path: '/login',
        component: LoginView,
        meta: {
            title: 'Авторизація',
            layout: AuthLayout
        },
    },
    {
        path: '/',
        component: OrdersView,
        name: 'orders-list',
        meta: {
            title: 'Список замовлень',
            requiresAuth: true,
        },
    },
    {
        path: '/orders/create',
        name: 'create-order',
        component: OrderView,
        props: {isEditMode: false},
        meta: {
            title: 'Нове замовлення',
            breadcrumb: 'Нове замовлення',
            requiresAuth: true
        },
        redirect: to => {
            return {name: 'create-order-categories'}
        },
        children: [
            {
                path: '/orders/create/category',
                name: 'create-order-categories',
                component: CategoryView,
                meta: {
                    title: 'Категорії',
                    breadcrumb: 'Категорії',
                    requiresAuth: true
                },
            },
            {
                path: '/orders/create/category/:slug',
                name: 'create-order-products',
                component: CategoryChildView,
                meta: {
                    title: 'Продукти',
                    breadcrumb: 'Продукти',
                    requiresAuth: true
                },
            },
        ],
    },
    {
        path: '/order/:id',
        name: 'edit-order',
        component: OrderView,
        props: {isEditMode: true},
        meta: {
            title: 'Редагування замовлення',
            requiresAuth: true
        },
        redirect: to => {
            return {name: 'edit-order-categories'}
        },
        children: [
            {
                path: '/order/:id/category',
                name: 'edit-order-categories',
                component: CategoryView,
                meta: {
                    title: 'Категорії',
                    requiresAuth: true
                },
            },
            {
                path: '/order/:id/category/:slug',
                name: 'edit-order-products',
                component: CategoryChildView,
                meta: {
                    title: 'Продукти',
                    requiresAuth: true
                },
            },
        ],
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    const authStore = useAuthStore();
    const roleCookie = Cookies.get('role');
    if (to.meta.requiresAuth && (!authStore.isAuthenticated || !roleCookie)) {
        next('/login')
    } else {
        next()
    }
})

// Зміна мета-заголовка документа під час навігації
router.beforeEach((to, from, next) => {
    const defaultTitle = 'Orders';
    document.title = to.meta.title || defaultTitle;
    next();
});


export default router;
