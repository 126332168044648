<script>
import api from "../api.js";
import TopBar from "@/js/components/top-bar.vue";
import Cookies from 'js-cookie';
import {useInternalStore} from "../stores/internal.js";
import {useConfigStore} from "../stores/config.js";

export default {
    name: "App",
    components: { TopBar},
    setup(){
        const internalStore = useInternalStore();
        const configStore = useConfigStore();
        return {internalStore, configStore};
    },
    data() {
        return {
            userAuthorized: false,
            isAdmin:false,
            loading: false,
            userRoles: [],
            role:null
        }
    },
    mounted() {
        // КОСТИЛЬ ДЛЯ ТОГО ЩОБ ЗАКРИВАЛАСЬ КЛАВІАТУРА В САФАРІ
        document.addEventListener('click', ($event) => {
            if ($event.target.classList.contains('qty__input') && $event.target.closest('.search__field')) {
                document.activeElement.blur();
            }
        })
        this.$mitt.on('show-global-loader', (flag) => {
            this.globalLoaderToggle(flag)
        })

        this.$mitt.on('user-change-role', (v) => {
            new Promise((resolve) => {
                this.configStore.setUserRole(v);
                resolve();
            }).then(() => {
                window.location.reload();
            })

        })
    },
    methods: {
        globalLoaderToggle(flag) {
            this.loading = flag;
        }
    }
}
</script>

<template>
    <v-app>
        <top-bar/>
        <v-main class="content" style="min-height: 300px;">
            <slot/>
        </v-main>
        <v-overlay v-model="loading"
                   persistent
                   class="align-center justify-center loader__overlay"
        >
            <v-progress-circular
                color="primary"
                size="64"
                indeterminate
            ></v-progress-circular>
        </v-overlay>
    </v-app>
</template>

<style lang="scss">
.v-bottom-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
}

.v-input {
    &.v-input--error {
        .v-input__details {
            display: block;
        }
    }

    .v-input__details {
        display: none;
    }
}
.loader__overlay{
    .v-overlay__scrim{
        backdrop-filter: blur(10px);
        background: rgba(255, 255, 255, 0.3);
        opacity:1;
    }

}
</style>
